import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">Our Solutions</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                    <Card title="Hospi App" img="card2.png" text="Find oppotunities near you. Use your skills to support the healthcare system" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Get Protected" img="card1.png" text="Work in a hassle and risk free environment" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Lifelong Learner" img="card3.png" text="Learn from the industry experts and enhance your skills on the go" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
