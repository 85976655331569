function Pricing() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container pricing">


            <h2 className="main-title text-center">PRICING</h2>
            <p className="main-p text-center"> Given are the subscription plans for hospitals and clinics</p>
            <table class="center">
                <tr>
                    <th>.</th>
                    <th>Basic</th>
                    <th>Standard</th>
                    <th>Premium</th>
                </tr>
                <tr>
                    <td>Price/Year**</td>
                    <td>Free</td>
                    <td>INR 4999</td>
                    <td>INR 24999</td>
                </tr>
                <tr>
                    <td>No. of User Accounts</td>
                    <td>1</td>
                    <td>5</td>
                    <td>20</td>
                </tr>
                <tr>
                    <td>No. of Job Posts/Year</td>
                    <td>10</td>
                    <td>100</td>
                    <td>1000</td>
                </tr>
            </table>
            <p className="main-p text-center"> ** Given prices are excluding taxes</p>
        </div>
    </>
  );
}
export default Pricing;
