function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="./img/img1.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
              Hospihands mission is to make healthcare accessible in India. Hospihands aims to create an enabling ecosystem for the healthcare professionals while driving innovation and growth in the sector. Our platform helps healthcare professionals in finding suitable opportunities near them. It connects healthcare organisations with qualifed professionals suitable as per desired needs
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
