function Header() {
  return (
    <header>
      <video src="./video.mp4" loop autoPlay muted></video>
      <h1>Welcome! to Hospihands</h1>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
